import axios from 'axios';
import store from '@/store';
import CookieHelper from '@/helpers/CookieHelper';
import { get_all_from_storage, clear_register_form_local_storage } from "@/helpers/storage/RegisterFormHelper";

const register_path = "v1/trainee/auth/register";
const resend_email_verification_path = "v1/trainee/auth/email/resend";

function register()
{
    return new Promise((resolve, reject) =>{
        const data = get_all_from_storage();

        // console.log(data);

        axios.post(register_path, data)
            .then((response)=>{
                CookieHelper.setToken(response.data.token);

                store.commit('setUser', response.data.trainee);
                store.commit('setToken', response.data.token);

                clear_register_form_local_storage();

                resolve(response);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function register_validation(data)
{
    return new Promise((resolve, reject) =>{
        axios.post(register_path, data)
            .then(()=>{
                resolve({ success: true });
            })
            .catch((err) => {
                // check for 422 validation errors only.
                if(err.response.status === 422){
                    // find the errors matching the local keys to be tested, the rest can be discarded
                    const local_keys = Object.keys(data);
                    const errors = {}
                    for (const [key, value] of Object.entries(err.response.data.errors)) {
                        if (local_keys.includes(key)){
                            errors[key] = value;
                        }
                    }
                    if(isEmptyObject(errors)){
                        resolve({ success: true });
                    } else {
                        resolve({ success: false, message: err.response.data.message, errors: errors });
                    }
                } else {
                    reject(err.response.data);
                }
            });
    });
}

function isEmptyObject(obj)
{
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

function resendVerificationEmail()
{
    return new Promise((resolve, reject) =>{
        axios.post(resend_email_verification_path)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { register, resendVerificationEmail, register_validation };