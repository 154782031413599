import store from '@/store';

const ls_prefix = "register_form_"; // local storage prefix

function register_form_to_storage(name)
{
    return {
        get() {
            const storeValue = store.state.registerForm.form[name];

            if(storeValue){
               return storeValue;
            }

            const localValue = localStorage.getItem(ls_prefix + name); 

            if (localValue){
                store.commit('registerForm/setFormValue',{
                    key: name,
                    value: localValue,
                });
                
            }

            return localValue;
        },
        set(value){
            store.commit('registerForm/setFormValue',{
                key: name,
                value: value,
            });
            if (name !== "password" && name !==  "password_confirmation"){
                localStorage.setItem(ls_prefix + name, value);
            }
        }
    };
}
function error_to_store(name)
{
    return {
        get() {
            return store.getters['registerForm/error'](name);
        },
        set(value){
            store.commit('registerForm/setError',{
                key: name,
                error: value,
            });
        }
    };
}

function clear_register_form_local_storage ()
{
    var arr = []; 
    for (let i = 0; i < localStorage.length; i++){
        if (localStorage.key(i).startsWith(ls_prefix)) {
            arr.push(localStorage.key(i));
        }
    }
    for (let i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i]);
    }
}

function get_all_from_storage ()
{
    let data = Object.assign({}, store.getters['registerForm/form']);

    // retrieve local storage values if vuex store has null value.
    for (const [key, value] of Object.entries(data)) {
        if (value === null || value === undefined || value === ""){
            const cachedVal = localStorage.getItem(ls_prefix + key);
            // console.log('value of "' + key + '" is null, inserting cached value instead: ', cachedVal ?? "");
            data[key] = cachedVal ?? "";
        }
    }
    return data;
}

export { register_form_to_storage, error_to_store, clear_register_form_local_storage, get_all_from_storage }