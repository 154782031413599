<template>
    <Guest :title="t('page_title')">
        <div class="py-8">
            <form v-on:submit.prevent="navigateNext">
                <p class="text-white mb-6">
                    <i18n-t keypath="views.Register6.i_agree">
                        <template v-slot:terms>
                            <a target="_blank\" class="underline" href="https://www.scorelit.com/voorwaarden/">
                                {{ t('terms') }}
                            </a>
                        </template>
                        <template v-slot:privacy>
                            <a target="_blank" class="underline" href="https://www.scorelit.com/privacy-statement/">
                                {{ t('privacy') }}
                            </a>
                        </template>
                    </i18n-t>
                </p>
                
                <QuestionWithOptions v-model:value="terms_and_conditions" v-model:errors="all_errors" :question_container="{ question: '', options: [{ id: 0, name: t('no') }, {id: 1, name: t('yes')}]}" />
                <div class="mt-8">
                    <button type="submit" class="button w-full">
                        {{ t('done') }}
                    </button>
                </div>
            </form>
        </div>
    </Guest>
</template>

<script>
import { register } from "@/helpers/api/RegistrationHelper";
import router from "@/router";
import Guest from "@/wrappers/Guest";
import QuestionWithOptions from "@/components/forms/QuestionWithOptions";
import { register_form_to_storage } from "@/helpers/storage/RegisterFormHelper";
import messages from "@/helpers/storage/MessageHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Register6",
    components:{
        Guest,
        QuestionWithOptions,
    },
    computed:{
        terms_and_conditions: register_form_to_storage('terms_and_conditions'),
    },
    setup() {
        return useI18n({
            viewPrefix: "Register6"
        });
    }, 
    data(){
        return {
            all_errors: [],
        };
    },
    methods: {
        navigateNext() {
            setLoading(true);

            register()
                .then(()=>{

                    window.gtag('event', 'registered', {
                        'event_category': 'account',
                        'event_label': 'trainee',
                    });

                    router.push({name: "dashboard"});
                })
                .catch((data)=>{
                    const error_values = Object.values(data.errors);
                    this.all_errors = error_values.flat(1);

                    if (error_values.length > 1 || ! ('terms_and_conditions' in data.errors)) {
                        // more errors than just on this page:
                        messages.error(this.t('messages.something_went_wrong'));
                        router.push({name: 'register'});
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>

<style scoped>

</style>
